<template>
  <div component-name="btn-handle-favourite">
    <btn-icon
      :title="
        alreadyAdded
          ? $t.labels.remove_from_favourites
          : $t.labels.add_to_favourites
      "
      :icon="alreadyAdded ? 'favourite-added' : 'favourite'"
      :disabled="isLoading"
      variant="rounded-filled"
      :size="$attrs.size"
      @click.stop.prevent="handleFavourites"
    />

    <template v-if="!userStore.logged">
      <modal-add-to-favourites ref="tpl_modal" />
    </template>
  </div>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
const props = defineProps({
  isFav: {
    type: Boolean,
    required: false,
  },
  slug: {
    type: String,
    required: false,
  },
  imgId: {
    type: [Number, String],
    required: false,
  },
});

const nuxtApp = useNuxtApp();
const route = useRoute();
const tpl_modal = templateRef("tpl_modal");
const isLoading = ref(false);
const userStore = useUserStore();

const alreadyAdded = computed(() => {
  if (!userStore.favourites?.slugs.length) {
    return false;
  }
  const product = userStore.favourites.slugs.find((slug) => slug == props.slug);
  const media = userStore.favourites.img_ids.find(
    (img_id) => img_id == props.imgId
  );
  return !!product || !!media;
});

function handleFavourites() {
  if (userStore.logged) {
    isLoading.value = true;

    const data = {
      moodboard_slug: "favourites",
    };

    if (props.slug) {
      data.product_slug = props.slug;
    } else if (props.imgId) {
      data.img_id = props.imgId;
      data.href =
        "/?redirect=" +
        route.path.replace(
          new RegExp(`/${route.params.country}/${route.params.locale}/`),
          ""
        );
    }

    const method = alreadyAdded.value
      ? userStore.removeFromMoodboard
      : userStore.addToMoodboard;

    return method(data)
      .then((response) => {
        if (!response) {
          console.log(nuxtApp.$t.labels.something_went_wrong);
        }
        const { status, error } = response;

        if (status == "error") {
          console.log(nuxtApp.$t.labels.something_went_wrong);
        }

        return response;
      })
      .catch((error) => {
        console.log("error", error);
        console.log(nuxtApp.$t.labels.something_went_wrong);
        isLoading.value = false;
      });
  } else if (tpl_modal.value) {
    tpl_modal.value.open();
  }
}
</script>
