<template>
  <client-only>
    <TransitionRoot :show="isOpen" as="template">
      <Dialog
        component-name="modal-add-to-favourites"
        data-skin="white"
        class="relative z-50"
        data-lenis-prevent
      >
        <TransitionChild
          enter="transition-opacity duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <!-- The backdrop, rendered as a fixed sibling to the panel container -->
          <div class="modal-backdrop" aria-hidden="true" @click="close()"></div>
        </TransitionChild>

        <!-- Full-screen scrollable container -->
        <div
          class="pointer-events-none overflow-clip fixed inset-0 flex justify-end w-full h-full"
        >
          <!-- Container to center the panel -->
          <div class="flex justify-end items-stretch w-full h-full max-h-full">
            <TransitionChild
              enter="transition-transform duration-300 ease-out delay-200"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transition-transform duration-300 ease-in"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <!-- The actual dialog panel -->
              <component
                :is="$device.isMobile ? 'div' : DialogPanel"
                data-skin="white"
                class="pointer-events-auto overflow-y-auto flex flex-col w-[calc(100vw-60px)] max-w-[700px] h-full max-h-full bg-skin-base p-edge"
              >
                <menu-user class="w-full" :onSigninSuccess="onSigninSuccess" />
              </component>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </client-only>
</template>

<script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { useUserStore } from "@/stores/user";

const route = useRoute();
const isOpen = ref(false);
const userStore = useUserStore();

function open() {
  isOpen.value = true;
}

function close() {
  isOpen.value = false;
}

function onSigninSuccess() {
  console.log("onSigninSuccess");
  userStore.validate();
}

watch(route, close);

watch(
  () => userStore.logged,
  () => {
    if (userStore.logged) {
      close();
    }
  }
);

defineExpose({
  open,
});
</script>
